/* Components & Widgets */
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function BoardHeader({
  children,
  title,
  tabs = []
}: {
  children: JSX.Element | JSX.Element[];
  title: string;
  tabs?: any;
}): JSX.Element {
  const router = useRouter();
  const { t } = useTranslation('common');

  return (
    <div className="w-full bg-gray-50 dark:bg-dark-faded sticky top-0">
      <div className="bg-white dark:bg-dark-primary relative z-10 shadow pt-3">
        <div className="flex items-center justify-between pt-10 md:max-w-[100%] lg:max-w-[1280px] lg:pl-16">
          {<div className="text-3xl font-bold tracking-tighter">{title}</div>}
          <div className="flex-1 ml-4 flex items-center"></div>

          <div className="px-4"></div>
          <div className="px-4"></div>
          <div className="px-4"></div>
          <div className="px-4"></div>
        </div>

        <div className="flex items-center justify-between px-4 md:max-w-[100%] lg:max-w-[1280px] lg:pl-16 pt-4">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              {t('navigation.tabs.select-tab')}
            </label>

            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              onChange={(e) => {
                const targetElement = e.target as HTMLSelectElement;
                const tab = tabs[parseInt(targetElement.value)];
                const shallow = Boolean(tab.shallow);
                router.push(
                  { pathname: tab.href, query: tab.query },
                  {},
                  {
                    shallow
                  }
                );
              }}
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
              {tabs.map((tab, idx) => (
                <option key={idx} value={idx}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>

          <div className="hidden sm:block">
            <div>
              <nav className="-mb-px flex space-x-12 h-[40px]" aria-label="Tabs">
                {tabs.map((tab, idx) => {
                  const shallow = tab?.shallow ? true : false;
                  const selected: boolean = router.asPath?.startsWith(tab?.href);

                  return (
                    <Link
                      key={idx}
                      shallow={shallow}
                      href={{
                        pathname: tab.href
                      }}>
                      <span
                        className={classNames(
                          selected
                            ? 'border-bobo-prop dark:border-dark-prop text-bobo-black font-medium'
                            : 'border-transparent text-gray-400 font-medium hover:text-gray-700 hover:border-gray-300',
                          'whitespace-nowrap py-1 pt-4 px-1 border-b-4  text-xs uppercase'
                        )}
                        aria-current={tab?.href === router?.asPath ? 'page' : undefined}>
                        {tab.name}
                      </span>
                    </Link>
                  );
                })}
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-[100%] lg:max-w-[1080px] m-auto">
        <div className="w-full p-4">{children}</div>
      </div>
    </div>
  );
}
