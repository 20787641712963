/* External Libraries */
import type { NextPage } from 'next';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import Container from 'src/layout/container';
import BoardContainer from 'src/layout/header/board';

import { makeStaticProps } from '@/src/api/i18nUtil';

const Home: NextPage = () => {
  const { t } = useTranslation('common');
  return (
    <Container bgClass="!bg-gray-50 dark:!bg-dark-faded">
      <Head>
        <title>{t('pages.404-page-not-found')}</title>
      </Head>

      <div>
        <BoardContainer title={t('general.oops')}>
          <div>{t('pages.404-page-not-found')}</div>
        </BoardContainer>
      </div>
    </Container>
  );
};

export default Home;

export async function getStaticProps({ locale }) {
  return makeStaticProps(locale);
}
